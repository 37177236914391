import React, { useState } from "react";

import {
  MDBJumbotron,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
  MDBMask,
  MDBView,
} from "mdbreact";

import InfoProject from "../containers/InfoProject";

import "./styles.css";

const Service = () => {
  const [statusInfoProject, setStatusInfoProject] = useState(false);
  const [tour, setTour] = useState([]);

  const importAll = (r) => {
    return r.keys().map(r);
  };

  const sales = importAll(require.context("../sales", false, /\.(json)$/));

  return (
    <React.Fragment>
      <MDBJumbotron id="service" className="container-sale w-100">
        <MDBContainer>
          <h2 className="display-5 text-center mb-5">NUESTROS SERVICIOS</h2>
          <MDBRow>
            <MDBCol sm="12" md="3" className="mb-5">
              <MDBCard>
                <MDBView className="hover">
                  <img
                    src={require("../images/inmobiliaria.jpg")}
                    className="img-fluid"
                    alt=""
                  />
                  <MDBMask className="flex-center" overlay="blue-light">
                    <p className="white-text" style={{ fontSize: "14px" }}>
                      COMPRA Y VENTA DE INMUEBLES
                    </p>
                  </MDBMask>
                </MDBView>
              </MDBCard>
            </MDBCol>

            <MDBCol sm="12" md="3" className="mb-5">
              <MDBCard>
                <MDBView className="hover">
                  <img
                    src={require("../images/construccion2.png")}
                    className="img-fluid"
                    alt=""
                  />
                  <MDBMask className="flex-center" overlay="blue-light">
                    <p className="white-text" style={{ fontSize: "14px" }}>
                      CONSTRUCCIÓN EN OBRAS CIVILES
                    </p>
                  </MDBMask>
                </MDBView>
              </MDBCard>
            </MDBCol>

            <MDBCol sm="12" md="3" className="mb-5">
              <MDBCard>
                <MDBView className="hover">
                  <img
                    src={require("../images/licencias.jpg")}
                    className="img-fluid"
                    alt=""
                  />
                  <MDBMask className="flex-center" overlay="blue-light">
                    <p className="white-text" style={{ fontSize: "14px" }}>
                      LICENCIAS DE CONSTRUCCIÓN
                    </p>
                  </MDBMask>
                </MDBView>
              </MDBCard>
            </MDBCol>

            <MDBCol sm="12" md="3" className="mb-5">
              <MDBCard>
                <MDBView className="hover">
                  <img
                    src={require("../images/planos.jpg")}
                    className="img-fluid"
                    alt=""
                  />
                  <MDBMask className="flex-center" overlay="blue-light">
                    <p className="white-text" style={{ fontSize: "14px" }}>
                      PLANOS Y DISEÑOS
                    </p>
                  </MDBMask>
                </MDBView>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBJumbotron>
      <InfoProject
        isOpen={statusInfoProject}
        setIsOpen={setStatusInfoProject}
        tour={tour}
      />
    </React.Fragment>
  );
};

export default Service;
