import React, { useEffect } from "react";

import { MDBRow, MDBCol, MDBContainer, MDBBtn, MDBCardText } from "mdbreact";

import Navbar from "../components/Navbar";
import Logo from "../components/Logo";
import Whatsapp from "../components/Whatsapp";
import Sale from "../components/Sale";
import NewProject from "../components/NewProject";
import Project from "../components/Project";
import AboutUs from "../components/AboutUs";
import Service from "../components/Service";
import Contact from "../components/Contact";

import BackgroundImg from "../images/house.jpg";

import "./styles.css";

const Home = () => {
  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <MDBContainer className="container-home w-80">
      <Whatsapp />
      <MDBRow className="overlay"></MDBRow>
      <MDBRow
        className="img"
        style={{
          backgroundImage: `url(${BackgroundImg})`,
        }}
      ></MDBRow>
      <MDBRow>
        <Navbar />
        <MDBCol sm="6">
          <MDBBtn
            color="green"
            style={{ fontSize: 17, fontWeight: "bold", border: 30 }}
            size="100"
            onClick={() => handleClickScroll("new_project_ebenezer6")}
          >
            NUEVO PROYECTO EBENEZER VI
            <br />
            Apartamentos de 50m² hasta 66m² desde $265'272.000 con acabados,
            ascensor, con o sin parqueadero.
            <br />
            Y locales comerciales de 35m² hasta 65m² desde $267'000.000.
            <br />
            <br />
            Para más información de clic sobre este mensaje
          </MDBBtn>
        </MDBCol>
        <MDBCol sm="6">
          <MDBBtn
            color="orange"
            style={{ fontSize: 17, fontWeight: "bold", border: 30 }}
            size="100"
            onClick={() => handleClickScroll("new_project_ebenezer5")}
          >
            NUEVO PROYECTO EBENEZER V<br />
            APARTAMENTOS DESDE: $231'650.000 (51m²)
            <br />
            Hasta: $475'000.000 (89m²)
            <br />
            <br />
            Para más información de clic sobre este mensaje
          </MDBBtn>
        </MDBCol>

        <Logo />
        <Sale />
        <NewProject />
        <Project />
        <AboutUs />
        <Service />
        <Contact />
      </MDBRow>
    </MDBContainer>
  );
};

export default Home;
