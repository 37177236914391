import React, { useState, useEffect } from "react";

import ImageGallery from "react-image-gallery";

import "react-image-gallery/styles/css/image-gallery.css";

const View = ({ folder, type, images }) => {
  const [tourImages, setTourImages] = useState([]);

  useEffect(() => {
    const tour = [];
    images.forEach((element) => {
      tour.push({
        original:
          element.type === "generic"
            ? require(`../images/${folder}/generic/images/${element.image}`)
            : require(`../images/${folder}/${type}/images/${element.image}`),
        thumbnail:
          element.type === "generic"
            ? require(`../images/${folder}/generic/images/${element.image}`)
            : require(`../images/${folder}/${type}/images/${element.image}`),
        description: element.description,
      });
    });
    setTourImages(tour);
  }, []);

  return <ImageGallery items={tourImages} autoPlay={false} />;
};

export default View;
