import React, { useState, useEffect } from "react";

import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBRow,
  MDBCol,
  MDBTooltip,
} from "mdbreact";

import Scene from "../components/Scene";
import View from "../components/View";
import Video from "../components/Video";

import Info from "../components/Info";

import "./styles.css";

const InfoProject = ({ isOpen, setIsOpen, tour, sale }) => {
  const [scene, setScene] = useState(0);
  const [type, setType] = useState("");

  const toggle = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (tour.scenes && tour.scenes.length > 0) {
      setType("image");
    } else if (tour.images && tour.images.length > 0) {
      setType("image");
    } else if (tour.videos && tour.videos.length > 0) {
      setType("video");
    }
  }, [tour]);

  return (
    <React.Fragment>
      <MDBContainer>
        <MDBModal isOpen={isOpen} centered size="lg" backdrop="static">
          <MDBModalHeader toggle={toggle}>{tour.title}</MDBModalHeader>
          <MDBModalBody>
            <MDBRow>
              <MDBCol>
                {tour.images && tour.images.length > 0 ? (
                  <MDBTooltip placement="top">
                    <MDBBtn
                      size="sm"
                      color="info"
                      onClick={() => setType("image")}
                    >
                      <img
                        className="icon-info"
                        src={require(`../images/icons/foto.png`)}
                        alt={"imágenes"}
                      />
                    </MDBBtn>
                    <div>Ver Imágenes</div>
                  </MDBTooltip>
                ) : (
                  ""
                )}
                {tour.videos && tour.videos.length > 0 ? (
                  <MDBTooltip placement="top">
                    <MDBBtn
                      size="sm"
                      color="info"
                      onClick={() => setType("video")}
                    >
                      <img
                        className="icon-info"
                        src={require(`../images/icons/video.png`)}
                        alt={"video"}
                      />
                    </MDBBtn>
                    <div>Ver Video</div>
                  </MDBTooltip>
                ) : (
                  ""
                )}
                {tour.scenes && tour.scenes.length > 0 ? (
                  <MDBTooltip placement="top">
                    <MDBBtn
                      size="sm"
                      color="info"
                      onClick={() => setType("360")}
                    >
                      <img
                        className="icon-info"
                        src={require(`../images/icons/360.png`)}
                        alt={"Tour 360°"}
                      />
                    </MDBBtn>
                    <div>Ver Panorámica 360°</div>
                  </MDBTooltip>
                ) : (
                  ""
                )}
              </MDBCol>
            </MDBRow>
            <MDBRow>
              {type === "360" ? (
                <MDBCol sm="8">
                  <Scene
                    folder={tour.folder}
                    type={tour.type}
                    scene={tour.scenes[scene]}
                    setScene={setScene}
                  />
                </MDBCol>
              ) : (
                ""
              )}
              {type === "image" ? (
                <MDBCol sm="8">
                  <View
                    folder={tour.folder}
                    type={tour.type}
                    images={tour.images}
                  />
                </MDBCol>
              ) : (
                ""
              )}
              {type === "video" ? (
                <MDBCol sm="8">
                  <Video
                    folder={tour.folder}
                    type={tour.type}
                    videos={tour.videos}
                  />
                </MDBCol>
              ) : (
                ""
              )}
              <MDBCol sm="4">
                <Info
                  sale={sale}
                  type={type}
                  tour={tour}
                  scene={scene}
                  setScene={setScene}
                />
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>
      </MDBContainer>
    </React.Fragment>
  );
};

export default InfoProject;
