import React, { useState } from "react";

import { Player } from "video-react";

import { MDBBtn, MDBRow, MDBCol } from "mdbreact";

import "video-react/dist/video-react.css";

export default ({ folder, type, videos }) => {
  const [video, setVideo] = useState(0);

  return (
    <React.Fragment>
      <Player
        fluid={false}
        width={"100%"}
        height={400}
        playsInline
        autoPlay={true}
        startTime={0}
        src={
          videos[video].type === "generic"
            ? require(`../images/${folder}/generic/videos/${videos[video].video}`)
            : require(`../images/${folder}/${type}/videos/${videos[video].video}`)
        }
      />
      {videos.length > 1 ? (
        <MDBRow>
          <MDBCol className="">
            <MDBBtn
              disabled={video > 0 ? false : true}
              size="sm"
              color={video > 0 ? "info" : ""}
              className="ml-0"
              onClick={() => {
                setVideo(video - 1);
              }}
            >
              ANTERIOR
            </MDBBtn>
          </MDBCol>
          <MDBCol className="text-right">
            <MDBBtn
              disabled={
                videos.length > 1 && video < videos.length - 1 ? false : true
              }
              size="sm"
              color={
                videos.length > 1 && video < videos.length - 1 ? "info" : ""
              }
              className="mr-0"
              onClick={() => {
                setVideo(video + 1);
              }}
            >
              SIGUIENTE
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};
