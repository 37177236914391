import React from "react";

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

import "./styles-info.css";

const Info = ({ type, sale, tour, scene, setScene }) => {
  return (
    <MDBRow>
      <MDBContainer>
        <MDBRow className="pl-3 pt-2">
          <MDBCol className="import">
            {tour.built_area ? (
              <MDBRow className="mb-1">
                <MDBCol size="2">
                  <img
                    className="icon-info"
                    src={require("../images/icons/area2.png")}
                    alt="Área"
                  />
                </MDBCol>
                <MDBCol size="10">
                  <span className="align-middle text-info-span">
                    {tour.built_area}m<sup>2</sup> Área Construida
                  </span>
                </MDBCol>
              </MDBRow>
            ) : null}
            <MDBRow className="mb-1">
              <MDBCol size="2">
                <img
                  className="icon-info"
                  src={require("../images/icons/area.png")}
                  alt="Área"
                />
              </MDBCol>
              <MDBCol size="10">
                <span className="align-middle text-info-span">
                  {tour.private_area}m<sup>2</sup> Área Privada
                </span>
              </MDBCol>
            </MDBRow>
            {/* <MDBRow className="mb-1">
              <MDBCol size="2">
                <img
                  className="icon-info"
                  src={require("../images/icons/precio.png")}
                  alt="Área"
                />
              </MDBCol>
              <MDBCol size="10">
                <span className="align-middle text-info-span">
                  $ {tour.price}
                </span>
              </MDBCol>
            </MDBRow> */}
            {sale ? (
              <MDBRow className="mb-1">
                <MDBCol size="2"></MDBCol>
                <MDBCol size="10">
                  <span
                    className="align-middle text-info-span"
                    style={{ color: "green" }}
                  >
                    {tour.delivery}
                  </span>
                </MDBCol>
              </MDBRow>
            ) : (
              ""
            )}
          </MDBCol>
        </MDBRow>

        {tour.areas.map((element) => {
          return (
            <MDBRow
              className={`mb-1 select ${
                scene === element.id && type == "360" ? "selected" : ""
              }`}
              onClick={() => setScene(element.id)}
            >
              <MDBCol size="2">
                <img
                  className="icon-info"
                  src={require(`../images/icons/${element.icon}`)}
                  alt={element.name}
                />
              </MDBCol>
              <MDBCol size="10">
                <span className="align-middle text-info-span">
                  {element.name}
                </span>
              </MDBCol>
            </MDBRow>
          );
        })}
      </MDBContainer>
    </MDBRow>
  );
};

export default Info;
