import React, { useState } from "react";

import {
  MDBJumbotron,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from "mdbreact";

import InfoProject from "../containers/InfoProject";

import "./styles.css";

const Project = () => {
  const [statusInfoProject, setStatusInfoProject] = useState(false);
  const [tour, setTour] = useState([]);

  const importAll = (r) => {
    return r.keys().map(r);
  };

  const sales = importAll(require.context("../projects", false, /\.(json)$/));

  return (
    <React.Fragment>
      <MDBJumbotron id="project" className="container-sale w-100">
        <MDBContainer>
          <h2 className="display-5 text-center mb-5">PROYECTOS REALIZADOS</h2>
          {/* <MDBRow>
            <p>
              Queremos que veas la calidad de nuestros acabados es por ello que
              te presentamos los proyectos que hemos construido.{" "}
            </p>
          </MDBRow> */}
          <MDBRow>
            {sales.map((element) => {
              return (
                <MDBCol sm="12" md="4" className="mb-5">
                  <MDBCard>
                    <div className="sale-image text-center">
                      <img
                        className="img-fluid-card text-center"
                        src={require(`../images/${element.folder}/generic/images/${element.image}`)}
                        waves
                      />
                    </div>

                    <MDBCardBody className="text-center">
                      <MDBCardTitle>{element.title}</MDBCardTitle>
                      <MDBCardText style={{ textAlign: "justify" }}>
                        {element.description}
                      </MDBCardText>
                      <MDBBtn
                        color="primary"
                        onClick={() => {
                          setTour(element);
                          setStatusInfoProject(true);
                        }}
                      >
                        VER EL PROYECTO
                      </MDBBtn>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              );
            })}
          </MDBRow>
        </MDBContainer>
      </MDBJumbotron>
      <InfoProject
        sale={false}
        isOpen={statusInfoProject}
        setIsOpen={setStatusInfoProject}
        tour={tour}
      />
    </React.Fragment>
  );
};

export default Project;
