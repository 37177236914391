import React, { useState } from "react";

import {
  MDBJumbotron,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
} from "mdbreact";

import { Player } from "video-react";

import InfoProject from "../containers/InfoProject";

import "video-react/dist/video-react.css";

import "./styles.css";

const Sale = () => {
  const [statusInfoProject, setStatusInfoProject] = useState(false);
  const [tour, setTour] = useState([]);

  const importAll = (r) => {
    return r.keys().map(r);
  };

  const ebenezer5 = importAll(
    require.context("../newprojects/ebenezer5", false, /\.(json)$/)
  );

  const ebenezer6 = importAll(
    require.context("../newprojects/ebenezer6", false, /\.(json)$/)
  );

  return (
    <React.Fragment>
      <MDBJumbotron id="sale" className="container-new-project w-100">
        <MDBContainer id="new_project_ebenezer6">
          <h2 className="display-5 text-center mb-5">
            NUEVO PROYECTO EN VENTA - EBENEZER VI
          </h2>
          <MDBRow style={{ padding: 15 }}>
            <Player
              fluid={false}
              width={"100%"}
              height={400}
              playsInline
              autoPlay={false}
              startTime={0}
              src={require(`../images/ebenezer6/generic/videos/ebenezer6.mp4`)}
            />
          </MDBRow>
          <MDBRow>
            {ebenezer6.map((element) => {
              return (
                <MDBCol sm="12" md="4" className="mb-5">
                  <MDBCard>
                    <div className="sale-image text-center">
                      {element.price ? (
                        <div
                          className="textCenter"
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "35%",
                            width: "100%",
                            fontSize: "20px",
                          }}
                        >
                          <p className="price">{element.price}</p>
                          <p className="elements">{element.elements}</p>
                          <p className="elements">{element.floor}</p>
                          <p className="notAvailable">{element.important}</p>
                        </div>
                      ) : element.price === null ? (
                        <div
                          className="textCenter"
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "25%",
                            width: "100%",
                            fontSize: "20px",
                          }}
                        >
                          <p className="notAvailable">NO DISPONIBLES</p>
                        </div>
                      ) : null}
                      <img
                        className="img-fluid-card text-center"
                        src={require(`../images/${element.folder}/generic/images/${element.image}`)}
                        waves
                      />
                    </div>

                    <MDBCardBody className="text-center">
                      <MDBCardTitle>{element.title}</MDBCardTitle>
                      <MDBCardText style={{ textAlign: "justify" }}>
                        {element.description}
                      </MDBCardText>
                      <MDBBtn
                        color="primary"
                        onClick={() => {
                          setTour(element);
                          setStatusInfoProject(true);
                        }}
                      >
                        VER EL PROYECTO
                      </MDBBtn>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              );
            })}
          </MDBRow>
        </MDBContainer>

        <MDBContainer id="new_project_ebenezer5">
          <h2 className="display-5 text-center mb-5">
            NUEVO PROYECTO EN VENTA - EBENEZER V
          </h2>
          <MDBRow style={{ padding: 15 }}>
            <Player
              fluid={false}
              width={"100%"}
              height={400}
              playsInline
              autoPlay={false}
              startTime={0}
              src={require(`../images/ebenezer5/generic/videos/ebenezer5.mp4`)}
            />
          </MDBRow>
          <MDBRow>
            {ebenezer5.map((element) => {
              return (
                <MDBCol sm="12" md="4" className="mb-5">
                  <MDBCard>
                    <div className="sale-image text-center">
                      {element.price ? (
                        <div
                          className="textCenter"
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "35%",
                            width: "100%",
                            fontSize: "20px",
                          }}
                        >
                          <p className="price">{element.price}</p>
                          <p className="elements">{element.elements}</p>
                          <p className="elements">{element.floor}</p>
                          <p className="notAvailable">{element.important}</p>
                        </div>
                      ) : element.price === null ? (
                        <div
                          className="textCenter"
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "25%",
                            width: "100%",
                            fontSize: "20px",
                          }}
                        >
                          <p className="notAvailable">NO DISPONIBLES</p>
                        </div>
                      ) : null}
                      <img
                        className="img-fluid-card text-center"
                        src={require(`../images/${element.folder}/generic/images/${element.image}`)}
                        waves
                      />
                    </div>

                    <MDBCardBody className="text-center">
                      <MDBCardTitle>{element.title}</MDBCardTitle>
                      <MDBCardText style={{ textAlign: "justify" }}>
                        {element.description}
                      </MDBCardText>
                      <MDBBtn
                        color="primary"
                        onClick={() => {
                          setTour(element);
                          setStatusInfoProject(true);
                        }}
                      >
                        VER EL PROYECTO
                      </MDBBtn>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              );
            })}
          </MDBRow>
        </MDBContainer>
      </MDBJumbotron>
      <InfoProject
        sale={true}
        isOpen={statusInfoProject}
        setIsOpen={setStatusInfoProject}
        tour={tour}
      />
    </React.Fragment>
  );
};

export default Sale;
