import React, { Component } from "react";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBJumbotron,
} from "mdbreact";
import { BrowserRouter as Router } from "react-router-dom";

import { Link, animateScroll as scroll } from "react-scroll";

class Navbar extends Component {
  state = {
    isOpen: false,
  };

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    return (
      <MDBJumbotron className="container-navbar w-100 sticky-top">
        <Router>
          <MDBNavbar dark expand="md">
            <MDBNavbarBrand>
              <strong className="white-text pl-2">EBENEZER</strong>
            </MDBNavbarBrand>
            <MDBNavbarToggler onClick={this.toggleCollapse} />
            <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
              <MDBNavbarNav right>
                <MDBNavItem active>
                  <Link
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    offset={this.state.isOpen ? -225 : 0}
                    duration={500}
                    to="sale"
                  >
                    <MDBNavLink to="">APARTAMENTOS EN VENTA</MDBNavLink>
                  </Link>
                </MDBNavItem>
                <MDBNavItem>
                  <Link
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    offset={this.state.isOpen ? -225 : 0}
                    duration={500}
                    to="project"
                  >
                    <MDBNavLink to="">PROYECTOS REALIZADOS</MDBNavLink>
                  </Link>
                </MDBNavItem>
                <MDBNavItem>
                  <Link
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    offset={this.state.isOpen ? -225 : 0}
                    duration={500}
                    to="service"
                  >
                    <MDBNavLink to="">NUESTROS SERVICIOS</MDBNavLink>
                  </Link>
                </MDBNavItem>
                <MDBNavItem>
                  <Link
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    offset={this.state.isOpen ? -225 : 0}
                    duration={500}
                    to="aboutus"
                  >
                    <MDBNavLink to="">QUIÉNES SOMOS</MDBNavLink>
                  </Link>
                </MDBNavItem>
                <MDBNavItem>
                  <Link
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    offset={this.state.isOpen ? -225 : 0}
                    duration={500}
                    to="contact"
                  >
                    <MDBNavLink to="">CONTACTANOS</MDBNavLink>
                  </Link>
                </MDBNavItem>
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBNavbar>
        </Router>
      </MDBJumbotron>
    );
  }
}

export default Navbar;
