import React, { useState } from "react";

import {
  MDBJumbotron,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
} from "mdbreact";

import InfoProject from "../containers/InfoProject";

import "./styles.css";

const Sale = () => {
  const [statusInfoProject, setStatusInfoProject] = useState(false);
  const [tour, setTour] = useState([]);

  const importAll = (r) => {
    return r.keys().map(r);
  };

  const sales = importAll(require.context("../sales", false, /\.(json)$/));

  return (
    <React.Fragment>
      {sales.length > 0 ? (
        <MDBJumbotron id="sale" className="container-sale w-100">
          <MDBContainer>
            <h2 className="display-5 text-center mb-5">PROYECTOS EN VENTA</h2>
            <MDBRow>
              {sales.map((element) => {
                return (
                  <MDBCol sm="12" md="4" className="mb-5">
                    <MDBCard>
                      <div className="sale-image text-center">
                        {element.price ? (
                          <div
                            className="textCenter"
                            style={{
                              display: "block",
                              position: "absolute",
                              top: "35%",
                              width: "100%",
                              fontSize: "20px",
                            }}
                          >
                            <p className="price">{element.price}</p>
                            <p className="elements">{element.elements}</p>
                            <p className="elements">{element.floor}</p>
                            <p className="notAvailable">{element.important}</p>
                          </div>
                        ) : element.price === null ? (
                          <div
                            className="textCenter"
                            style={{
                              display: "block",
                              position: "absolute",
                              top: "25%",
                              width: "100%",
                              fontSize: "20px",
                            }}
                          >
                            <p className="notAvailable">NO DISPONIBLES</p>
                          </div>
                        ) : null}
                        <img
                          className="img-fluid-card text-center"
                          src={require(`../images/${element.folder}/generic/images/${element.image}`)}
                          waves
                        />
                      </div>

                      <MDBCardBody className="text-center">
                        <MDBCardTitle>{element.title}</MDBCardTitle>
                        <MDBCardText style={{ textAlign: "justify" }}>
                          {element.description}
                        </MDBCardText>
                        <MDBBtn
                          color="primary"
                          onClick={() => {
                            setTour(element);
                            setStatusInfoProject(true);
                          }}
                        >
                          VER EL PROYECTO
                        </MDBBtn>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                );
              })}
            </MDBRow>
          </MDBContainer>
        </MDBJumbotron>
      ) : null}
      <InfoProject
        sale={true}
        isOpen={statusInfoProject}
        setIsOpen={setStatusInfoProject}
        tour={tour}
      />
    </React.Fragment>
  );
};

export default Sale;
