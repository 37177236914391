import React from "react";

import { MDBJumbotron, MDBContainer, MDBRow, MDBCol, MDBCard } from "mdbreact";

import "./styles.css";

const Contact = () => {
  return (
    <MDBJumbotron id="contact" className="container-contact w-100">
      <MDBContainer>
        <h2 className="display-5 text-center mb-5">CONTACTATE CON NOSOTROS</h2>
        <MDBRow>
          <MDBCol sm="6" className="mb-3">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d15907.777124192058!2d-74.13372118505245!3d4.604000196484457!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1strv%2035%2027b%2021%20sur!5e0!3m2!1ses-419!2sco!4v1594436312903!5m2!1ses-419!2sco"
              height="450"
              width="100%"
              frameborder="0"
              style={{ border: 0 }}
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </MDBCol>
          <MDBCol sm="6" className="pl-5 pr-5">
            <MDBRow>
              <p style={{ textAlign: "justify" }}>
                EBENEZER PROYECTOS INMOBILIARIOS S.A.S
              </p>
            </MDBRow>

            <MDBRow>
              <p style={{ textAlign: "justify" }}>Nit. 901.089.888-4</p>
            </MDBRow>

            <MDBRow>
              <p style={{ textAlign: "justify" }}>
                Si estas interesado en alguno de nuestros servicios, requieres
                información adicional de los proyectos o deseas agendar una
                visita al apartamento de tu interés, por favor comunicate con
                nosotros por cualquiera de los siguientes medios o a través del
                chat y te responderemos en el menor tiempo posible.
              </p>
            </MDBRow>

            <MDBRow className="pb-3">
              <MDBCol size="1">
                <img
                  className="icon-info"
                  src={require("../images/icons/whatsapp.png")}
                  alt="Télefono"
                />
              </MDBCol>
              <MDBCol size="11">
                <span className="align-middle text-info-span">3175075669</span>
              </MDBCol>
            </MDBRow>

            <MDBRow className="pb-3">
              <MDBCol size="1">
                <img
                  className="icon-info"
                  src={require("../images/icons/phone.png")}
                  alt="Télefono"
                />
              </MDBCol>
              <MDBCol size="11">
                <span className="align-middle text-info-span">3175075669</span>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol size="1">
                <img
                  className="icon-info"
                  src={require("../images/icons/email.png")}
                  alt="Télefono"
                />
              </MDBCol>
              <MDBCol size="11">
                <span className="align-middle text-info-span">
                  informacion@ebenezerproyectos.com
                </span>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBJumbotron>
  );
};

export default Contact;
