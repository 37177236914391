import React from "react";

import { MDBJumbotron, MDBContainer, MDBRow, MDBCol } from "mdbreact";

import LogoImg from "../images/logo.png";

import "./styles.css";

const Logo = () => {
  return (
    <MDBJumbotron className="container-logo w-100">
      <MDBContainer>
        <MDBRow>
          <MDBCol className="text-center">
            <img src={LogoImg} />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className="text-center">
            <p className="text-logo">
              Somos una empresa con más de 15 años de experiencia diseñando,
              construyendo y comercializando proyectos inmobiliarios propios.
              Generamos espacios calidos y armoniosos con materiales de la más
              alta calidad y los mejores acabados.
            </p>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBJumbotron>
  );
};

export default Logo;
