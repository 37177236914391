import React from "react";

import { MDBJumbotron, MDBContainer, MDBRow, MDBCol, MDBCard } from "mdbreact";

import "./styles.css";

const AboutUs = () => {
  return (
    <MDBJumbotron id="aboutus" className="container-aboutus w-100">
      <MDBContainer className="pl-4 pr-4">
        <h2 className="display-5 text-center mb-3">QUIÉNES SOMOS</h2>
        <MDBRow>
          <h2 className="display-5 text-center">MISIÓN</h2>
        </MDBRow>

        <MDBRow>
          <p style={{ textAlign: "justify" }}>
            Somos una empresa constructora dedicada a la ejecución de proyectos
            de vivienda y obras civiles en el ámbito público y privado, cuya
            misión es satisfacer las necesidades de nuestros clientes antes,
            durante y después de finalizado el proyecto. Apoyados de la
            tecnología y el talento humano capacitado y comprometido. Dando
            cumplimiento a los estándares de calidad, por medio de la exigencia
            en el control de calidad de nuestros productos terminados. Y así,
            seguir aportando al desarrollo de la economía y a una mejor
            sociedad.
          </p>
        </MDBRow>

        <MDBRow>
          <h2 className="display-5 text-center mt-3">VISIÓN</h2>
        </MDBRow>

        <MDBRow>
          <p style={{ textAlign: "justify" }}>
            En EBENEZER PROYECTOS INMOBILIARIOS queremos convertirnos en una de
            las empresas líder en construcción y consultoría, reconocida por su
            capacidad, calidad y cumplimiento de las más exigentes normas de
            seguridad; utilizando procedimientos constructivos innovadores que
            permiten generar obras más confortables, que brinden una mejor
            calidad de vida de las familias y empresas que confían en nosotros.
          </p>
        </MDBRow>

        <MDBRow>
          <h2 className="display-5 text-center mt-3">VALORES EBENEZER</h2>
        </MDBRow>

        <MDBRow>
          <cite className="text-center mb-3" style={{ fontStyle: "italic" }}>
            “Samuel tomó una piedra a la que llamó Eben Ezer (piedra del
            socorro) y la dispuso para recordar que allí había sido el lugar
            donde los hebreos recibieron el auxilio del Señor”.
          </cite>
        </MDBRow>
        <MDBRow>
          <MDBRow>
            <p style={{ textAlign: "justify" }}>
              En <b>EBENEZER PROYECTOS INMOBILIARIOS</b> creemos que los logros
              que hemos alcanzado han sido por el talento humano capacitado y
              comprometido, siempre de la mano de Dios. Es por ésto que forjamos
              nuestros cimientos con los siguientes valores:
            </p>
          </MDBRow>
          <MDBRow>
            <p style={{ textAlign: "justify" }}>
              <b>Honestidad</b>: Creemos firmemente en las relaciones humanas
              fundamentadas en la honestidad y transparencia. Ofreciendo
              seguridad y credibilidad, construyendo una sociedad que valora la
              verdad, sin engaños ni trampas.
            </p>
          </MDBRow>
          <MDBRow>
            <p style={{ textAlign: "justify" }}>
              <b>Compromiso</b>: Vamos más allá de cumplir una obligación.
              Ofrecemos lo mejor de nuestras capacidades para sacar adelante
              todo proyecto que se nos ha confiado.
            </p>
          </MDBRow>
          <MDBRow>
            <p style={{ textAlign: "justify" }}>
              <b>Respeto</b>: Creemos que es un valor primordial para toda
              relación humana creando así un ambiente más agradable, beneficioso
              y productivo para todos.{" "}
            </p>
          </MDBRow>
          <MDBRow>
            <p style={{ textAlign: "justify" }}>
              <b>Convicción</b>: Nos sentimos orgullosos de cada uno de los
              éxitos de EBENEZER PROYECTOS INMOBILIARIOS a través de nuestros
              años de experiencia. Visualizamos el futuro con optimismo.
            </p>
          </MDBRow>
        </MDBRow>
      </MDBContainer>
    </MDBJumbotron>
  );
};

export default AboutUs;
