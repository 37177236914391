import React from "react";

import { Pannellum } from "pannellum-react";

import "./styles.css";

const Scene = ({ folder, type, scene, setScene }) => {
  if (scene === undefined) {
    setScene(0);
  }
  const hotspot = (hotSpotDiv, args) => {
    hotSpotDiv.classList.add("custom-tooltip");
    var span = document.createElement("span");
    span.innerHTML = args.text;
    const imageDiv = document.createElement("img");
    imageDiv.src = require(`../images/icons/${args.icon}`);
    imageDiv.style.width = "25px";
    imageDiv.style.padding = "8px";
    var filter = "contrast(460%)";
    imageDiv.style["-webkit-filter"] = filter;
    imageDiv.style["filter"] = filter;
    hotSpotDiv.appendChild(span);
    hotSpotDiv.appendChild(imageDiv);
    span.style.width = span.scrollWidth - 0 + "px";
    span.style.marginLeft =
      -(span.scrollWidth - hotSpotDiv.offsetWidth) / 2 + "px";
    span.style.marginTop = -span.scrollHeight - 12 + "px";
  };

  return (
    <Pannellum
      width={scene.width}
      height={scene.height}
      image={
        scene.type === "generic"
          ? require(`../images/${folder}/generic/360/${scene.image}`)
          : require(`../images/${folder}/${type}/360/${scene.image}`)
      }
      pitch={scene.pitch}
      yaw={scene.yaw}
      hfov={scene.hfov}
      autoLoad
    >
      {scene.hostpots.map((element) => {
        return (
          <Pannellum.Hotspot
            type={element.type}
            pitch={element.pitch}
            yaw={element.yaw}
            text={element.text}
            name={element.type}
            cssClass="hostspotcss"
            tooltip={hotspot}
            tooltipArg={{ text: element.text, icon: element.icon }}
            handleClickArg={element.scene === 0 ? -1 : element.scene}
            handleClick={(evt, id) => {
              if (id !== null) {
                setScene(id === -1 ? 0 : id);
              }
            }}
          />
        );
      })}
    </Pannellum>
  );
};

export default Scene;
