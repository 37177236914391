import React from "react";

import { MDBJumbotron, MDBContainer, MDBRow, MDBCol } from "mdbreact";

import LogoImg from "../images/icons/whatsapp.png";

import "./styles.css";

const Logo = () => {
  const whatsapp = () => {
    let url = `https://wa.me/${573175075669}`;

    // Appending the message to the URL by encoding it
    url += `?text=${encodeURI(
      "Hola, Me encuentro interesad@ en conocer los proyectos de apartamentos que ví en su página web."
    )}`;

    // Open our newly created URL in a new tab to send the message
    window.open(url);
  };

  return (
    <img
      src={LogoImg}
      className="container-whatsapp"
      onClick={() => whatsapp()}
    />
  );
};

export default Logo;
